import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import { Message, MessageBox } from 'element-ui';
import { getToken } from '@/utils/token';
import { getUserAndToken, isHttps } from '@/utils/utils';
import Cookies from 'js-cookie';
import qs from 'qs';
import _ from 'lodash';
import router from '@/router';

const errorNetwork = _.throttle(() => {
  Message({
    message: '网络有问题,请检查网络是否正常',
    type: 'error',
    duration: 5 * 1000,
  });
}, 5000);

// axios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";
// axios.defaults.headers.common['x-fbads-account'] = ''
axios.defaults.headers['Content-Type'] = 'application/json';
let baseURL = 'api';
let alertTime = '';
let timeout = 20000;
let _isHttps = isHttps();
export function operate401() {
  // 阻止重复弹出,记录上次弹框时间
  let curTime = new Date().getTime();
  if (alertTime && curTime - alertTime < 6000) {
    return false;
  } else {
    alertTime = curTime;
  }
  MessageBox.confirm('确认重新登录吗?', '重新登录确认', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    showClose: false,
    closeOnClickModal: false,
  })
    .then(() => {
      Cookies.remove('token');
      let left = (window.screen.width - 500) / 2,
        top = (window.screen.height - 500) / 2;
      window.open(`${process.env.VUE_APP_DOMAIN}/#/ad/loginBack`, '', `width=900,height=600,left=${left},top=${top}`);
      //   window.open('/api/login/relogin', '', `width=500,height=500,left=${left},top=${top}`);
    })
    .catch(() => {
      Message({
        type: 'info',
        message: '已取消重新登录！',
      });
    });
}

export function accountAbnormal() {
  MessageBox.confirm(`当前的广告帐号不可用，请切换帐号后重试！`, '更换帐号', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      Message({
        message: '请前往账号列表或广告资产页面切换帐号！',
        type: 'info',
      });
    })
    .catch(() => {
      Message({
        message: '已取消更换帐号！',
        type: 'info',
      });
    });
}
export const BASEURL = baseURL;
// create an axios instance
const service = axios.create({
  baseURL: baseURL, //
  withCredentials: false, // send cookies when cross-domain requests
  timeout: timeout, // request timeout
  transformRequest: [
    function (data) {
      // let ret = '';
      // for (let it in data) {
      //   ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      // }
      // return ret
      return qs.stringify(data);
    },
  ],
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.headers['thirdLoginUserId'] && !config.notThirdLoginUserId) {
      let id = store.state.num.selectedNum ? store.state.num.selectedNum : null;
      config.headers['thirdLoginUserId'] = id;
    }
    // if(!config.headers['token']&&store.state.user.userInfo.data){
    //   config.headers['token'] = store.state.user.userInfo.data.token;
    // }
    if (!config.headers['adAccountId'] && !config.notThirdLoginUserId) {
      let adAccountId = null;
      if (config.params) {
        adAccountId = config.params.accountId || config.params.account_id || config.params.adAccountId;
      }
      if (!adAccountId && config.data) {
        const _data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
        adAccountId = _data.account_id || _data.accountId || _data.adAccountId;
      }
      if (adAccountId) {
        config.headers['adAccountId'] = adAccountId;
      } else if (store.getters.accountId) {
        config.headers['adAccountId'] = store.getters.accountId;
      }
    }
    //多账户管理页面都不传个号
    if (router.currentRoute.fullPath == '/ad/multiAccountManageIndex') {
      delete config.headers.thirdLoginUserId;
    }
    // let token = getToken(); //Cookies.get('token'); //'179b007ec9edd5608245acc7611e1165';
    // if (token && !config.notNeedToken) {
    //   config.headers['token'] = token;
    // } else {
    // }
    const saToken = localStorage.getItem('saToken') || '';
    if (saToken && !config.notNeedToken) {
      config.headers['saToken'] = saToken;
      // config.headers['token'] = saToken;
      // config.headers['token'] = '014dd295c72db80123923d6d1db39962';
    }
    // config.headers['cookie'] = '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code == '401' || res.code == 401 || response.status == 401) {
      operate401();
    } else if (res.code != 0 && res.code != 200 && res.code != 11) {
      if (res.code == '2001815694') {
        //操作过程中帐号被封
        accountAbnormal();
      } else {
        if (!response.config.hideErrorMessage) {
          Message({
            message: res.comment || '接口错误',
            type: 'error',
            duration: 5 * 1000,
          });
        }
      }
    }
    return res;
  },
  (error) => {
    Vue.prototype.$hideLoading();
    if (error == 'Cancel') {
      return Promise.reject(error);
    }
    if (error.response && (error.response.status == '401' || error.response.status == 401)) {
      operate401();
    } else {
      if (error == 'Error: Network Error' || error == 'Network Error') {
        errorNetwork();
      } else if (error.response && error.response.status == '2001815694') {
        //操作过程中帐号被封
        accountAbnormal();
      } else {
        Message({
          message: error.message == 'timeout of 20000ms exceeded' ? '操作超时!' : error.message,
          type: 'error',
          duration: 5 * 1000,
        });
      }
    }
    if (error.response) {
      window.Smartlook &&
        window.Smartlook.error({
          message: error.response,
          type: '接口错误',
        });
    }
    return Promise.reject(error);
  },
);

export default service;
