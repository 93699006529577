// 保留两位小数
export const toFixed = {
  // 指令定义对象的钩子函数：inserted、update
  inserted(el, binding, vnode) {
    const input = el.querySelector('input');
    // 绑定事件：失去焦c点时触发
    input.addEventListener('blur', function () {
      const scale = el._attr_toFixed || binding.value || 2;
      // 获取输入框的值
      let value = input.value;
      // 判断是否是数字
      if (!isNaN(value) && value) {
        // 保留2位小数
        value = Number(value).toFixed(scale);
        // 更新输入框的值
        input.value = value;
        // 触发输入框的input事件，使v-model更新
        input.dispatchEvent(new Event('input'));
      }
    });
  },
  update(el, binding) {
    el._attr_toFixed = binding.value;
  },
};
// 限制最大字节
export const byteLimit = {
  inserted(el, binding, vnode) {
    console.log('aaa');
    el.maxBytes = binding.value; // 最大字节数
    el.addEventListener('blur', () => {
      const text = el.value;
      const bytes = new Blob([text]).size; // 计算字节长度
      console.log(bytes);
      if (bytes > el.maxBytes) {
        // 如果超过最大字节数，则截断文本
        el.value = text.substring(0, text.length - 1);
        // 触发输入框的input事件，使v-model更新
        input.dispatchEvent(new Event('input'));
      }
    });
  },
};
